// Apps Config
export const SET_MAIN_SIDE_NAV = "SET_MAIN_SIDE_NAV";
export const USER_LOADED = "USER_LOADED";

// Auth action
export const STORE_AUTH = "STORE_AUTH";
export const USER_ID = "USER_ID";
export const LOGOUT = "LOGOUT";
export const TOOGLE_NAV = "TOOGLE_NAV";

// Admin accounts
export const ACCOUNT_CATEGORY = "ACCOUNT_CATEGORY";
// Prepare test
export const ADD_TEST_DETAILS = "ADD_TEST_DETAILS";
export const ADD_TEST_QUESTION = "ADD_TEST_QUESTION";
export const ADD_TEST_QUESTION_ANSWER = "ADD_TEST_QUESTION_ANSWER";
export const REMOVE_TEST_QUESTION = "REMOVE_TEST_QUESTION";
export const REMOVE_TEST_QUESTION_ANSWER = "REMOVE_TEST_QUESTION_ANSWER";
export const CLEAR_TEST_TEMP = "CLEAR_TEST_TEMP";
export const GET_TESTS = "GET_TESTS";
export const CHANGE_TEST_STATUS = "CHANGE_TEST_STATUS";
export const LOAD_USER_TEST_DETAILS = "LOAD_USER_TEST_DETAILS";
export const SUBMIT_USER_TEST = "SUBMIT_USER_TEST";
export const ADD_USER_QUESTION_ANSWER = "ADD_USER_QUESTION_ANSWER";
export const LOAD_USER_TEST_RESULTS = "LOAD_USER_TEST_RESULTS";